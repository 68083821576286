<template>
  <div class="list_page2">
    <div class="box_con">
      <div class="box_list_wrap">
        <div class="box_shadow m_8">
          <div class="box box_one">
            <div
              class="box_list_wrap"
              v-for="(item, index) in items"
              :key="index"
              @click="fnPage(item.vote.voteNo)"
            >
              <div class="box_list">
                <div class="list_ttl">
                  <div class="left">
                    <div class="m_4">
                      <span class="build_name">{{ buildingName }}</span>
                    </div>
                    <p class="white_wrap">
                      {{ item.vote.title }}
                    </p>
                    <div class="price_box">
                      <img
                        src="@/assets/static/images/i_alarm.svg"
                        alt="icon"
                      />
                      <span class="price_txt"
                        >{{
                          $dayjs(item.vote.voteStartDate).format('YYYY.MM.DD')
                        }}
                        ~
                        {{
                          $dayjs(item.vote.voteEndDate).format('YYYY.MM.DD')
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="middle_box flex1none">
                    <div class="middle">
                      <p
                        class="price r_12"
                        :class="{
                          deposit: item.vote.isVoting === 1,
                          f_g: item.vote.isVoting === 2
                        }"
                      >
                        {{
                          item.vote.isVoting === 1 ? '투표 진행중' : '투표 종료'
                        }}
                      </p>
                      <p class="price r_12 deposit" v-show="item.isDone === 1">
                        참여 완료
                      </p>
                    </div>
                    <div class="icon"></div>
                  </div>
                </div>
              </div>
              <div class="line_grey none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'vote-li-list-index',
  description: '투표 리스트',
  props: {
    items: {
      type: [Array],
      default: () => {}
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const userData = computed(() => store.getters['user/getData'])

    const state = reactive({
      buildingName: userData.value.buildingName,
      isVote: userData.value.isVote
    })

    const fnPage = item => {
      router.push({ path: '/menu/vote/view', query: { voteNo: item } })
    }

    return { ...toRefs(state), fnPage }
  }
}
</script>

<style scoped></style>
