<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(items)">
          <vote-li-list-index :items="items" />
          <div v-observe-visibility="visibilityChanged"></div>
        </div>
        <empty-index :content="'투표 내역이 없습니다.'" v-else />
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import VoteLiListIndex from '@/components/views/vote/li-list'
import EmptyIndex from '@/components/views/empty/full'

const componentState = () => {
  const { proxy } = getCurrentInstance()

  const state = reactive({
    params: {
      page: 1,
      limit: 10
    },
    pageInfo: {},
    items: []
  })
  const fnListBox = async () => {
    try {
      const res = await proxy.$VoteSvc.fetchUserVoteList(state.params)
      if (res.code === 1) {
        state.pageInfo = res.entity.pageInfo
        state.items = state.items.concat(res.entity.list)
        state.params.page++
      }
    } catch (err) {
      console.error(err)
    }
  }

  const visibilityChanged = async isVisible => {
    if (isVisible) {
      if (state.pageInfo.totalPage < state.params.page) return false
      await fnListBox()
    }
  }
  fnListBox()
  return { ...toRefs(state), fnListBox, visibilityChanged }
}

export default {
  name: 'menu-vote-index',
  components: { EmptyIndex, VoteLiListIndex },
  description: '찬반투표 리스트 페이지',
  setup() {
    return {
      ...componentState()
    }
  }
}
</script>

<style scoped></style>
