<template>
  <div class="page_con page_bg">
    <div class="list_page">
      <div class="bill_speech_box">
        <img src="@/assets/static/images/i_speech.svg" alt="icon" />
        <span>{{ content }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'empty-full-index',
  description: '리스트 없을때 페이지 2',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
